<template>
  <div>
    <v-card-title class="pb-0 green darken-4">
      <p class="body-1 font-weight-medium">
        Credit Status
      </p>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="ml-2 pt-0">
      <v-row class="pt-0">
        <v-col
          cols="6"
          md="6"
        >
          <v-checkbox
            v-model="filters.credit"
            label="Credit"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <v-checkbox
            v-model="filters.noCredit"
            label="No Credit"
            @change="onCheckboxChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {
        credit: false,
        noCredit: false,
      },
      filterLabel: 'all customers',
    }
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = { ...this.filters, ...this.initialFilters }
    }
    this.onCheckboxChange()
  },
  methods: {
    onCheckboxChange() {
      const data = {
        filters: this.filters,
        label: this.filterLabel,
      }
      this.$emit('status-change', data)
    },
  },
}
</script>
