<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="150"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="text-none"
          v-on="on"
        >
          <v-icon
            left
            size="16"
          >
            {{ icons.mdiFilter }}
          </v-icon>
          <span class="font-weight-regular">Filter by {{ tableName }}</span>
          <span
            v-if="tableName !== ''"
            class="font-weight-black"
            style="margin-left:3px"
          > from </span>
          <span
            class="font-weight-black"
            style="margin-left:3px"
          > {{ filterLabel }}</span>
          <v-icon
            right
            size="16"
          >
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <date-filter
          v-if="showDateFilter"
          :default-filter="defaultDatePickerDate"
          @date-change="onDateChange"
        />
        <customer-account-status-filter
          v-if="showAccountBalanceStatusFilter"
          @status-change="onCustomerAccountStatusChange"
        />
        <payment-status-filter
          v-if="showPaymentStatusFilter"
          @status-change="onPaymentStatusChange"
        />
        <month-filter
          v-if="showMonthFilter"
          @month-change="onMonthChange"
        />
        <year-filter
          v-if="showYearFilter"
          @year-change="onYearChange"
        />
        <sms-status-filter
          v-if="showSmsStatusFilter"
          @status-change="onSmsStatusChange"
        />
        <meter-valve-status
          v-if="showMeterValveStatusFilter"
          @status-change="onMeterValveStatusChange"
        />
        <transaction-credit-status
          v-if="showTransactionCreditStatusFilter"
          @status-change="onTransactionCreditStatusChange"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="onFilterButtonClick"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiFilter,
  mdiChevronDown,
} from '@mdi/js'
import moment from 'moment'
import DateFilter from './DateFilter.vue'
import PaymentStatusFilter from './PaymentStatusFilter.vue'
import MonthFilter from './MonthFileter.vue'
import YearFilter from './YearFilter.vue'
import SmsStatusFilter from './SmsStatusFilter.vue'
import CustomerAccountStatusFilter from './CustomerAccountStatusFilter.vue'
import MeterValveStatus from './MeterValveStatus.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'
import TransactionCreditStatus from './TransactionCreditStatus.vue'

export default {
  components: {
    DateFilter, PaymentStatusFilter, MonthFilter, YearFilter, SmsStatusFilter, CustomerAccountStatusFilter, MeterValveStatus, TransactionCreditStatus,
  },
  mixins: [defaultMonthFilterDate],
  props: {
    tableName: {
      type: String,
      default: '',
    },
    showDateFilter: {
      type: Boolean,
      default: true,
    },
    showMonthFilter: {
      type: Boolean,
      default: false,
    },
    showYearFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentStatusFilter: {
      type: Boolean,
      default: false,
    },
    showSmsStatusFilter: {
      type: Boolean,
      default: false,
    },
    showAccountBalanceStatusFilter: {
      type: Boolean,
      default: false,
    },
    showMeterValveStatusFilter: {
      type: Boolean,
      default: false,
    },
    showTransactionCreditStatusFilter: {
      type: Boolean,
      default: false,
    },
    defaultDatePickerDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      icons: {
        mdiFilter,
        mdiChevronDown,
      },
      filterLabel: 'Last 30 days',
      filters: {},
    }
  },
  watch: {
    label(newVal) {
      if (newVal !== '' && this.showMonthFilter) {
        this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
      }
      if (newVal !== '' && this.showYearFilter) {
        this.filterLabel = moment().format('YYYY')
      }
    },
    defaultDatePickerDate(defaultDatePickerDate) {
      let fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      let toDate = moment().format('YYYY-MM-DD')
      if (defaultDatePickerDate === 'Last 1 year') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    },
  },
  mounted() {
    if (this.showDateFilter) {
      let fromDate
      let toDate

      fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')

      if (this.defaultDatePickerDate === 'Last 7 days') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      if (this.showTransactionCreditStatusFilter) {
        this.filters.transactionCreditStatus = {
          credit: false,
          noCredit: false,
        }
      }
      this.$emit('filter', this.filters)
    }
    if (this.showMonthFilter) {
      this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
    }
    if (this.showYearFilter) {
      this.filterLabel = moment().format('YYYY')
    }
    if (this.showAccountBalanceStatusFilter) {
      this.filterLabel = 'all customers'
    }
    if (this.showMeterValveStatusFilter) {
      this.filterLabel = 'all meters'
    }
  },
  methods: {
    onDateChange(value) {
      this.filters.dates = value.filters
      this.filterLabel = value.label
    },
    onMonthChange(value) {
      this.filters.month = value.filters
      this.filterLabel = value.label
    },
    onYearChange(value) {
      this.filters.year = value.filters
      this.filterLabel = value.label
    },
    onPaymentStatusChange(value) {
      this.filters.paymentStatus = value
    },
    onCustomerAccountStatusChange(value) {
      this.filters.accountBalanceStatus = value.filters
      if (value.label !== 'all customers') {
        this.filterLabel = `customers with ${value.label}`

        return
      }
      this.filterLabel = value.label
    },
    onSmsStatusChange(value) {
      this.filters.smsStatus = value
    },
    onMeterValveStatusChange(value) {
      this.filters.meterValveStatus = value.filters
      if (value.label !== 'all meters') {
        this.filterLabel = `meters with ${value.label}`

        return
      }
      this.filterLabel = value.label
    },
    onTransactionCreditStatusChange(value) {
      this.filters.transactionCreditStatus = value.filters
    },
    onFilterButtonClick() {
      this.menu = false
      this.$emit('filter', this.filters)
    },
  },

}
</script>
